<template>
  <div>
    <div class="container">

      <div class="dialogue-box has-header d-flex justify-content-center">
        <div class="dialogue-box-header">Marketplace</div>
        <div class="dialogue-box-inner w-100 p-4">
          <div v-if="categories.length === 0" class="text-center">Marketplace is looking a bit empty, fancy selling your things?</div>
          <ul class="list-unstyled d-flex flex-row flex-wrap">
            <li v-for="(cat, index) in categories" :key="index">
              <router-link :to="{name: 'MarketplaceListings', params: {category: cat.item_id}}">
                <Item :item="cat"/>
              </router-link>
            </li>
          </ul>
        </div>
        <div class="dialogue-bg"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Item from "@/components/items/Item.vue";

export default {
  name: 'Categories',
  components: {Item},
  mounted() {
    this.$socket.emit('marketplace:get categories')
  },
  computed: {
    ...mapGetters({
      categories: "marketplace/categories"
    })
  },
}
</script>